// @flow strict
import React from "react";
import Sidebar from "../components/Sidebar";
import Layout from "../components/Layout";
import Page from "../components/Page";
import useSiteMetadata from "../utils/use-site-metadata";

const NotFoundTemplate = () => {
  const { title, subtitle } = useSiteMetadata();

  return (
    <Layout title={`Not Found - ${title}`} description={subtitle}>
      <Sidebar />
      <Page title="NOT FOUND">
        <p>404 Error - This page does not exist</p>
      </Page>
    </Layout>
  );
};

export default NotFoundTemplate;
